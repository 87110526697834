@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

h1, h2, h3, h4, h5 {
    font-family: 'Lobster', cursive;
}

h2{
    font-weight: 700;
}

body{
    font-family: 'Quicksand', sans-serif;
}

.pointy{
    cursor: pointer;
}

label{
    margin-top: 5px;
    font-weight: 500;
}

.dropy{
    z-index: 2;
}

.logo{
    font-weight: 700;
}
