
$primary: #d83f87;
$secondary: #2a1b3d;
$warning: #44318d;
$danger:#e98074;
$success:#a4b3b6;
$dark: #1a1a1d;

$input-border-color: #4e4e50;

%loading-skeleton {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;
  
    &::placeholder {
      color: transparent;
    }
  }
  @keyframes loading-skeleton {
    from {
      opacity: .4;
    }
    to {
      opacity: 1;
    }
  }
  .loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
    
    img {
      filter: grayscale(100) contrast(0%) brightness(1.8);
    }
    h1, h2, h3, h4, h5, h6,
    p, li,
    .btn,
    label,
    .form-control {
      @extend %loading-skeleton;
    }
  }



@import '~bootstrap/scss/bootstrap.scss'; 

.container-fluid{
  height: auto;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
  outline: 0 !important;
}

.toast{
  bottom:0;
  margin-bottom: 70px;
  position: fixed;
}

.navbar .active {
  font-weight: 500;
}


@import '~react-toastify/scss/main.scss';
